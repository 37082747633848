// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-biq-index-jsx": () => import("./../../../src/pages/biq/index.jsx" /* webpackChunkName: "component---src-pages-biq-index-jsx" */),
  "component---src-pages-certification-index-jsx": () => import("./../../../src/pages/certification/index.jsx" /* webpackChunkName: "component---src-pages-certification-index-jsx" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-covid-index-jsx": () => import("./../../../src/pages/covid/index.jsx" /* webpackChunkName: "component---src-pages-covid-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-index-jsx": () => import("./../../../src/pages/media/index.jsx" /* webpackChunkName: "component---src-pages-media-index-jsx" */),
  "component---src-pages-method-index-jsx": () => import("./../../../src/pages/method/index.jsx" /* webpackChunkName: "component---src-pages-method-index-jsx" */),
  "component---src-pages-partner-index-js": () => import("./../../../src/pages/partner/index.js" /* webpackChunkName: "component---src-pages-partner-index-js" */),
  "component---src-pages-topics-backpain-index-js": () => import("./../../../src/pages/topics/backpain/index.js" /* webpackChunkName: "component---src-pages-topics-backpain-index-js" */),
  "component---src-pages-topics-covid-index-js": () => import("./../../../src/pages/topics/covid/index.js" /* webpackChunkName: "component---src-pages-topics-covid-index-js" */),
  "component---src-pages-topics-digestive-index-js": () => import("./../../../src/pages/topics/digestive/index.js" /* webpackChunkName: "component---src-pages-topics-digestive-index-js" */),
  "component---src-pages-topics-kids-index-js": () => import("./../../../src/pages/topics/kids/index.js" /* webpackChunkName: "component---src-pages-topics-kids-index-js" */),
  "component---src-pages-topics-mental-index-js": () => import("./../../../src/pages/topics/mental/index.js" /* webpackChunkName: "component---src-pages-topics-mental-index-js" */),
  "component---src-pages-topics-respiratory-index-js": () => import("./../../../src/pages/topics/respiratory/index.js" /* webpackChunkName: "component---src-pages-topics-respiratory-index-js" */)
}

